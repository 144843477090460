<template>
  <div class="kecheng">
    <kind-choose-view :schoole="schoole" :kind="kind" :zy-id="zyId" :show-kc="true" :type="117" :entrants-type="3"
      @isOk="isOk" />

    <!-- 最近直播  内容 -->
    <div v-if="list && list.length > 0" class="live">
      <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
        <div class="tips">
          <!-- <div v-if="item.productLine == 2" class="tips-title">精品</div> -->
          <img src="@/assets/img/partner/jp.png" alt="">
          <div class="tips-type">{{ liveEnum[item.types] + ' · ' + item.kindName }}</div>
        </div>
        <!-- 直播时间 -->
        <!-- 活动详情 -->
        <img class="item-img" :src="item.img">
        <div class="detail">
          <div class="detail-title twoEllipsis">{{ item.title }}</div>

          <!-- 价格 -->
          <div class="flex-between-c price-box">
            <div class="fire">
              <img src="@/assets/img/partner/hot.png" alt="">
              <div class="num">人气值{{ item.clicks }}</div>
            </div>
            <div v-if="university != 1" class="colleges-price" :class="item.productLine == 2 ? 'boutiquePrice' : ''">
              <span class="price-icon">￥</span>{{ item.price }}
            </div>
            <!-- <div class="flex-c">
              <div class="item-tips">{{ dealType(item.types) }}</div>
              <el-divider v-if="item.types != 5" direction="vertical" />
              <div v-if="item.types != 5" class="item-tips">
                {{ Math.floor(item.allTime) }}课时
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div class="flex-jc-c">
      <el-pagination class="pagination partner-pagination" :total="total" :current-page="pageNum" :page-size="pageSize"
        prev-text="上一页" next-text="下一页" :pager-count="5" layout="prev, pager, next"
        @current-change="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
import KindChooseView from '../../components/kindChooseViewjpkcSeventh.vue'
import { liveEnum } from '@/api/emun'
import { getDomain } from '@/api/cookies'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      list: [],
      total: 0,
      pageSize: 12,
      pageNum: 1,
      liveEnum: liveEnum,
      loading: null,
      schoole: null,
      zyId: null,
      kind: null,
      domainInfo: {},
      university: ''
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  async created() {
    this.domainInfo = getDomain()
    if (this.domainInfo.eId == process.env.VUE_APP_university) {
      this.university = 1
    }
    if (this.$route.query.kind) {
      this.kind = await Number(this.$route.query.kind)
    }
    if (this.$route.query.zId) {
      this.zyId = await Number(this.$route.query.zId)
    }
    if (this.$route.query.schoole) {
      this.schoole = await Number(this.$route.query.schoole)
    }
    // 调用轮播图导航接口方法
    this.getList()
  },
  methods: {
    dealType(type) {
      let temp = null
      switch (type) {
        case 1:
          temp = '直播'
          break
        case 2:
          temp = '录播'
          break
        case 3:
          temp = '音频'
          break
        case 4:
          temp = '图文'
          break
        case 5:
          temp = '面授'
          break
        default:
          ''
      }
      return temp
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: '/zhiboclassxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: '/videoxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: '/audioCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: '/imgCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: '/faceCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    // 获取列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      await know
        .getNewCourseList(
          this.pageSize,
          this.pageNum,
          undefined,
          this.search.kind ? this.search.kind : undefined,
          this.search.stageName ? this.search.stageName : undefined,
          this.search.zyId ? this.search.zyId : undefined,
          this.search.kcId ? this.search.kcId : undefined,
          this.search.productLine == 4 ? null : this.search.productLine,
          this.search.areaId ? this.search.areaId : undefined,
          this.search.types ? this.search.types : undefined
        )
        .then((res) => {
          this.total = res.total
          this.list = res.rows
        })
      this.loading.close()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    searchClick() {
      this.pageSize = 12
      this.pageNum = 1
      this.total = 0
      this.list = []
      this.getList()
    },
    // 筛选
    isOk(val) {
      console.log(val)
      this.search = val
      this.searchClick()
    },
    // 直播课详情
    zhiboke(item) {
      this.$router.push(`/zhiboclassxq?id=${item.id}&&type=2`)
    }
  }
}
</script>
<style lang="less" scoped>
.kecheng {
  padding-bottom: 24px;
}

// 最近直播  内容
.live {
  width: calc(1200px + 25px);
  margin-left: calc(50% - 600px);
  // margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;

  .item {
    margin-bottom: 20px;
    margin-right: 27px;
    overflow: hidden;
    cursor: pointer;
    width: 280px;
    height: 278px;
    // background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    position: relative;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .tips {
      position: absolute;
      z-index: 999;
      top: 0px;
      right: 0px;
      display: flex;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      text-align: center;

      img {
        width: 66px;
        // height: 22px;
      }

      .tips-type {
        position: absolute;
        top: 120px;
        left: -200px;
        padding: 2px 5px;
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.4);
        font-size: 10px;
      }

      .tips-title {
        width: 36px;
        height: 20px;
        background: linear-gradient(132deg, #ff9051 0%, #ff5e51 100%);
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
      }

      .tips-text {
        height: 20px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px 4px 4px 4px;
        padding: 0px 4px;
      }
    }

    .item-img {
      width: 100%;
      height: 157px;
      object-fit: cover;
      opacity: 1;
      object-fit: cover;
    }

    .detail {
      padding: 16px;
      background-image: url('../../assets/img/partner/jingpinBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .detail-title {
        width: 243px;
        height: 46px;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 23px;
      }

      .price-box {
        margin-top: 16px;

        .fire {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 12px;
            height: 15.5px;
            margin-right: 5px;
          }

          .num {
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #777a82;
          }
        }

        .item-tips {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
        }

        .colleges-price {
          height: 25px;
          font-size: 18px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #fb2d25;
          line-height: 25px;
        }

        .el-divider {
          margin: auto 8px;
          height: 8px;
          background: #999999;
        }
      }
    }
  }
}

.price-icon {
  font-size: 14px;

}
</style>
